import React, { Component } from "react";
import NavBar from "../component/navBar/index";
import Header from "../component/header/index";
import Statics from "../component/statics/index";
import AppFeature from "../component/appFeature/index";
import CheckOut from "../component/checkOut/index";
import ApplicationScreenshot from "../component/applicationScreenshot/index";
import TestImonials from "../component/testimonials/index";
import GetStart from "../component/getStart/index";
import Footer from "../component/footer/index";
import MobileFooter from "../component/mobilefooter/index";
import './style.css'

export default class landingPage extends Component {
  render() {
    return (
      <div>
        <section id="NavBar">
          <NavBar />
        </section>
        <section id="Header">
          <Header />
        </section>
        <section id="Statics">
          {" "}
          <Statics />
        </section>
        <section id="AppFeature">
          {" "}
          <AppFeature />
        </section>
        <section id="CheckOut">
          <CheckOut />
        </section>
        <section id="ApplicationScreenshot">
          {" "}
          <ApplicationScreenshot />
        </section>
        <section id="TestImonials">
          <TestImonials />
        </section>
        <section id="DownloadApp">
          <GetStart />
        </section>
        <section id="Footer" className="desktop-footer">
          {" "}
          <Footer />
        </section>
        <section id="Footer" className="mobile-footer">
          {" "}
          <MobileFooter />
        </section>
      </div>
    );
  }
}
